import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private http: HttpClient) { }

  visitedPage(path: string): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/utils/visitedPage', {path: path}, {withCredentials: true});
  }

  keepAlive(): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/utils/keepAlive', {}, {withCredentials: true});
  }

  radarChartSpentVsIncomes(year: number): Observable<any> {
    return this.http.post<Observable<any>>(environment.backendUrl + '/utils/radarChartSpentVsIncomes', {year: year}, {withCredentials: true});
  }
}
